import React, { useState, useEffect } from "react"
import Layout from "../../../../components/layout"
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Space,
  Button,
  Modal,
  Select,
} from "antd"
import { CpiService } from "../../../../services/cpi.service"
import { Link } from "gatsby"
import firebase from "gatsby-plugin-firebase"
import PageHeading from "../../../../components/pageHeading"
import { FileService } from "../../../../services/file.service"

export default function ConditionReport({ id }) {
  const [reportData, setReportData] = useState([])

  useEffect(() => {
    return firebase
      .firestore()
      .collection("cpi/" + id + "/alphaReport/")
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => data.push({ ...doc.data(), key: doc.id }))
        setReportData(data)
      })
  }, [])

  function AddReport({ visible, onCreate, onCancel, data }) {
    const [form] = Form.useForm()
    return (
      <Modal
        visible={visible}
        title="Condition Report"
        okText="Submit"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields()
              if (data.key) {
                onCreate(values, data.key)
              } else {
                onCreate(values)
              }
            })
            .catch(info => {
              //console.log("Validate Failed:", info)
            })
        }}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            initialValue={data ? data.name : null}
            rules={[
              {
                required: true,
                message: "Please input the name of the report",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="startTime"
            label="Start Year"
            initialValue={data ? data.startTime : null}
          >
            <Input placeholder="YYYY" />
          </Form.Item>
          <Form.Item
            name="endTime"
            label="End Year"
            initialValue={data ? data.endTime : null}
          >
            <Input placeholder="YYYY" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const [form] = Form.useForm()
  const [modalShow, setAddModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleDelete = key => {
    CpiService.deleteReport(id, key)
  }

  const onCreate = (values, key) => {
    Object.keys(values).forEach(key => {
      if (values[key] === null || values[key] === undefined) delete values[key]
    })

    //need update
    if (key) {
      CpiService.updateReport(id, key, values)
    } else {
      CpiService.createReport(id, values)
    }
    setAddModal(false)
    setModalData({})
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "36%",
      editable: true,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "20%",
      editable: true,
      sorter: {
        compare: (a, b) => a.startTime - b.startTime,
        multiple: 2,
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "20%",
      editable: true,
      sorter: {
        compare: (a, b) => a.endTime - b.endTime,
        multiple: 1,
      },
    },

    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Typography.Link
              onClick={() => {
                setAddModal(true)
                setModalData(record)
              }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <button>Delete</button>
            </Popconfirm>
            <Link
              to={"/filebrowser/"}
              state={{ path: "cpi/" + id + "/media/" + record.key + "/file/" }} //need update
            >
              Upload Files
            </Link>
          </Space>
        )
      },
    },
  ]

  return (
    <Layout>
      <PageHeading title="Instruments -  Condition Report" />
      <div className="flex flex-row justify-between p-3 pt-8">
        <Button
          type="primary"
          onClick={() => {
            setAddModal(true)
          }}
        >
          Add
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          className="w-screen"
          bordered
          dataSource={reportData}
          columns={columns}
        />
      </Form>
      <AddReport
        visible={modalShow}
        onCancel={() => {
          setAddModal(false)
          setModalData({})
        }}
        onCreate={onCreate}
        data={modalData}
      />
    </Layout>
  )
}
