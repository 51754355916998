import firebase from "gatsby-plugin-firebase"

export const FileService = {
  createFile,
  updateFile,
  deleteFile,
  deleteFolder,
  deleteLink,
}

async function createFile(data, dbPath) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  return await firebase
    .firestore()
    .collection(dbPath)
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      return docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function updateFile(data, dbPath) {
  data["lastModifyTime"] = firebase.firestore.FieldValue.serverTimestamp()
  await firebase
    .firestore()
    .doc(dbPath)
    .update(data)
    .then(console.log("Document successfully updated!"))
    .catch(error => {
      console.error("Error writing document: ", error)
    })
}

async function deleteFile(dbPath, storePath) {
  await firebase.firestore().doc(dbPath).delete()
  const storageRef = firebase.storage().ref()
  await storageRef.child(storePath).delete()
}

async function deleteLink(dbPath) {
  await firebase.firestore().doc(dbPath).delete()
}

async function deleteFolder(dbPath, storePath) {
  deleteDb(dbPath)
  deleteStore(storePath)
}

async function deleteDb(dbPath) {
  firebase.firestore().doc(dbPath).delete()
  // firebase
  //   .firestore()
  //   .collection(dbPath + "/file")
  //   .get()
  //   .toPromise()
  //   .then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //       doc.ref.delete()
  //     })
  //   })
}

async function deleteStore(storePath) {
  const storageRef = firebase.storage().ref(storePath)
  storageRef.listAll().then(listResults => {
    const promises = listResults.items.map(item => {
      return item.delete()
    })
    Promise.all(promises)
  })
}
